<template>

  <section class="forms">
    <div class="page-header">
      <h3 class="page-title">
        Add New City
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Cities</a></li>
          <li class="breadcrumb-item active" aria-current="page">New</li>
        </ol>
      </nav>
    </div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
    <form @submit.prevent="handleSubmit(onSubmit)" class="forms-sample">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">City Information</h4>
            <p class="card-description">
             name
            </p>
            <ValidationProvider name="City" rules="required">
              <b-form-group slot-scope="{ errors }" label="City"  label-for="city">
                <b-form-input type="text" id="city" v-model="city" placeholder="City"></b-form-input>
                <p>{{ errors[0] }}</p>
              </b-form-group>
              </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <b-form-group class="float-right">
              <b-button type="submit" variant="success" :disabled="invalid" class="mr-2">{{ create_text }}</b-button>
              <b-button type="button" @click="resetForm" variant="light">Reset</b-button>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    </form>
    </ValidationObserver>
    <vue-snotify></vue-snotify>
  </section>

</template>

<script lang="js">
/* eslint-disable no-debugger, no-console */

import { ValidationObserver } from 'vee-validate'

export default {
  name: 'cityAddNew',
  data () {
    return {
      city: "",
      create_text: "Create City"
    }
  },
  components: {
    ValidationObserver
  },
  methods: {
    onSubmit() {
      this.create_text = "Creating..."
      let payload = {
        name: this.city
      }
      this.$store.dispatch('city/createCity', payload)
      .then(() => {
        this.create_text = "Create Admin"
        this.resetForm()
        this.$snotify.success("Account created successfully!", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
      .catch(() => {
        this.create_text = "Create City"

        this.$snotify.warning("Something went wrong!", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
    },
    resetForm() {
      this.city = ""

      this.$nextTick(() => {
        this.$refs.form.reset();
      })
    }
  }
}
</script>
